<template>
   <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <div class="row" id="liveEvent">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <div class="row align-items-center mb-4">
                     <div class="col-md-6">
                        <div class="form-inline navbar-search">
                           <div class="input-group">
                              <input name="title" class="form-control bg-light border-0 small" placeholder="Search..."
                                 v-on:keyup="search" aria-label="Search" aria-describedby="basic-addon2"
                                 v-model="filter" />
                              <div class="input-group-append">
                                 <button class="btn btn-search" @click="searchFor">
                                    <i class="fas fa-search fa-sm"> </i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="all-tabs">
                           <div :class="activeTab == 'all' ? ' tab-options' : ''" @click.prevent="fetchData('all');currentPage=1"
                              class="mx-2 opt">
                              All
                           </div>
                           |
                           <div :class="activeTab == 'trash' ? ' tab-options' : ''" @click.prevent="fetchData('trash');currentPage=1"
                              class="mx-2 opt">
                              Trash
                           </div>
                           |
                           <div :class="activeTab == 'active' ? ' tab-options' : ''" @click.prevent="fetchData('active');currentPage=1"
                              class="mx-2 opt">
                              Active
                           </div>
                           <div class="d-flex align-items-center">
                              <svg class="svg-inline--fa fa-table mx-2" height="16px" width="16px" aria-hidden="true"
                                 focusable="false" data-prefix="fa" data-icon="table" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                 <path fill="currentColor"
                                    d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z">
                                 </path>
                              </svg>
                              <router-link v-can="'add-live-event'" :to="{ name: 'add-live-event' }" class="opt">Add
                                 Live Event
                              </router-link>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="table-responsive mb-0">
                     <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy"
                        :per-page="0" :current-page="currentPage" :sort-desc.sync="sortDesc"
                        :filter-included-fields="filterOn" @filtered="onFiltered" responsive="sm"
                        class="table-bordered table-hover" :key="key">
                        <template v-slot:cell(Dashboard)="row">
                           <router-link :to="{ name: 'live-event-dashboard', params: { id: row.item.id },}">
                              <b-button class="btn btn-sm btn-primary" variant="primary" dark text>
                                 analytics
                              </b-button>
                           </router-link>
                        </template>
                        <template v-slot:cell(title)="row">
                           <span class="promotion_heading">{{ row.item.title }}</span>
                        </template>
                        <template v-slot:cell(status)="row">
                           <b-form-checkbox switch class="mb-1" @change="updateStatus(row.item.id)"
                              :checked="row.item.is_active == 1 ? true : false">
                           </b-form-checkbox>
                        </template>
                        <template v-slot:cell(active_chat)="row">
                           <b-form-checkbox switch class="mb-1" @change="updateChatStatus(row.item.id)"
                              :checked="row.item.active_chat == 1 ? true : false">
                           </b-form-checkbox>
                        </template>
                        <template v-slot:cell(private_chat)="row">
                           <b-form-checkbox switch class="mb-1" @change="updatePrivateChat(row.item.id)"
                              :checked="row.item.private_chat == 1 ? true : false">
                           </b-form-checkbox>
                        </template>
                        <template v-slot:cell(moderator_link)="row">
                           <b-button variant="outline-secondary" class="btn-sm" @click="gotomoderator(row.item.id)">Go To
                              Moderator
                           </b-button>
                        </template>
                        <template v-slot:cell(has_certificates)="row">
                           <template v-if="row.item.has_certificates == 1">
                              <b-button variant="outline-primary" class="btn-sm m-1"
                                 @click="generateCertificate(row.item.id)">Regenerate Certificate
                              </b-button>
                              <router-link :to="{
                                 name: 'show-certificates',
                                 params: { id: row.item.id },
                              }"><b-button variant="outline-primary" class="btn-sm m-1">Show Certificate</b-button>
                              </router-link>
                           </template>
                        </template>
                        <template v-slot:cell(download)="row">
                           <b-button variant="outline-secondary" class="btn-sm" @click="download(row.item.id)">Download
                           </b-button>
                        </template>
                        <template v-slot:cell(sendEmail)="row">
                           <b-button variant="outline-success" class="btn-sm send-mail" @click="sendEmail(row.item.id)">
                              Send Email
                           </b-button>
                        </template>
                        <template v-slot:cell(edit)="row">
                           <template v-can="'update-live-event'">
                              <router-link :to="{
                                 name: 'edit-live-event',
                                 params: { id: row.item.id },
                              }">
                                 <i class="fa fa-edit edit-i"></i>
                              </router-link>
                           </template>
                        </template>
                        <template v-if="activeTab != 'trash'" v-slot:cell(delete)="row" v-can="'delete-live-event'">
                           <div @click.prevent="deleteLiveEvent(row.item.id)">
                              <i class="mdi mdi-trash-can delete-i"></i>
                           </div>
                        </template>
                        <!-- <template v-else-if="activeTab == 'trash'" v-slot:cell(restore)="row" v-can="'restore-forum'">
                           <div  @click.prevent="restoreForum(row.item.id)">
                              <i class="mdi mdi-restore restore-i"></i>
                           </div>
                        </template> -->
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                      first-number
                      last-number
                    ></b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import liveEvent from "../../../mixins/ModuleJs/live-event";

export default {
  mixins: [MixinRequest, liveEvent],
  data() {
    return {
      title: "Live Event",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
